import React, { useState, useEffect } from "react";
import matter from "gray-matter";
import { Link } from "react-router-dom";

// Example static list of blog files (replace this with dynamic fetching later)
const blogFiles = [
  { slug: "stacks", filePath: "/blog/stacks.md" },
  { slug: "shamir", filePath: "/blog/shamir.md" },
];

function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const fetchedPosts = await Promise.all(
        blogFiles.map(async ({ slug, filePath }) => {
          const res = await fetch(filePath);
          const text = await res.text();
          const { data } = matter(text);
          return { slug, ...data };
        })
      );
      setPosts(fetchedPosts);
    }

    fetchPosts();
  }, []);

  return (
    <div className="min-h-screen bg-white font-inter">
      {/* Header section with subtle gradient */}
      <div className="bg-gradient-to-b from-gray-50 to-white py-16 md:py-24">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-5xl md:text-6xl font-bold text-gray-900 tracking-tight">
            blog
          </h1>
        </div>
      </div>

      {/* Posts section */}
      <div className="max-w-4xl mx-auto px-6 py-12">
        {posts.length === 0 ? (
          <div className="flex items-center justify-center h-32">
            <div className="text-xl text-gray-500 animate-pulse">
              loading...
            </div>
          </div>
        ) : (
          <div className="space-y-16">
            {posts.map((post) => (
              <article key={post.slug} className="group relative">
                <Link
                  to={`/blog/${post.slug}`}
                  className="block group-hover:translate-x-1 transition-transform duration-200 ease-out"
                >
                  <div className="space-y-3">
                    <time className="text-sm text-gray-500">{post.date}</time>

                    <h2 className="text-2xl font-medium text-gray-900 group-hover:text-gray-700">
                      {post.title}
                    </h2>

                    <p className="text-lg text-gray-600">{post.description}</p>
                  </div>
                </Link>

                {/* Hover indicator */}
                <div className="absolute -left-8 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  →
                </div>
              </article>
            ))}
          </div>
        )}

        {/* Empty state when no posts */}
        {posts.length === 0 && (
          <div className="mt-16 text-center text-gray-500">
            <p>No posts yet. Check back soon!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
